#button {
    margin-left: 1rem;
    background-color: #FFF7E8; 
    color:black;
}

#YlectureButton {
    margin-right: 1rem;
    margin-top: 0.5rem;
    background-color: #DCF9E2; 
    color:black;
}

#XlectureButton {
    margin-right: 1rem;
    margin-top: 0.5rem;
    background-color: #FFF7E8; 
    color:black;
}

#QuantButton {
    margin-right: 1rem;
    margin-top: 0.5rem;
    background-color: #C0CBFA; 
    color:black;
}

#youtube {
    margin-left: 0.3rem;
    margin-right: 0.3rem;
    text-decoration: none;
}


@media (max-width: 768px) {
    #flex-container {
        display: flex;
        flex-direction: column; /* Stack items vertically on smaller screens */
    }
    #font-size {
        font-size: "0.8rem"
    }
    #img-height {
        height: 30px;
    }
    #margin-logos {
        margin-bottom: 1rem;
    }
}

@media (min-width: 769px) {
    #flex-container {
        display: flex;
        flex-direction: row; /* Align items in a row on larger screens */
    }
    #margin-logos {
        margin-left: 7.3rem
    }
    #font-size {
        font-size: "1.3rem"
    }
    #img-height {
        height: 35px;
    }
}