.color-nav {
    background-color: #DEAA88;
}

#navbar-toggle {
    border: 1px solid, black
}

#flexRowStart {
    display: flex;
    flex-direction: row;
    justify-content: center; /* Align items horizontally to the center */
    flex: auto;
  }
  
  #flexRowStretch {
    display: flex;
    flex-direction: row;
    justify-content: center; /* Align items horizontally to the center */
    align-items: stretch; /* Align items vertically to the center */
    flex-wrap: wrap;
    flex: auto;
  }
  
  
  #flexColStart {
    display: flex;
    flex-direction: column;
    justify-content: space-between; /* aligns items along the main axis */
    align-items: flex-start; 
    flex-wrap: wrap;
  
  }
  