.color-nav {
  background-color: #DEAA88;
}



#left-bar {
  background-color: #FFF7E8; 
  color:black;
}


#right-bar {
  background-color: #DCF9E2; 
  /*background-color: red;  */
  color:black;
}

#first-bar {
  background-color: #FFF7E8; 
  color:black;
}

#second-bar {
  background-color: #DCF9E2; 
  color:black;
}

#purple-bar {
  background-color: #C0CBFA; 
  color:black;
}


#button-1 {
  background-color: #FFF7E8; 
  color:black;
}

#button-2 {
  background-color: #DCF9E2;
  color: black;
}

#button-3 {
  background-color: #C0CBFA;
  color: black;
  margin-left: 0.3rem;
  margin-right: 0.3rem;
}

#map, #map2 {
  height:500px;
}

.korea-color {
  background-color: #81012C;
}


.w-85 {
  width: 85% !important;
}

#flexCol {
  display: flex;
  flex-direction: column;
  justify-content: space-between; /* aligns items along the main axis */
  align-items: center; 
  flex-wrap: wrap;
}

#container {
  width: 80%;
}

#flexRow {
  display: flex;
  flex-direction: row;
  justify-content: center; /* Align items horizontally to the center */
  align-items: center; /* Align items vertically to the center */ 
  flex-wrap: wrap;
}

#flexRowStart {
  display: flex;
  flex-direction: row;
  justify-content: center; /* Align items horizontally to the center */
  align-items: flex-start; /* Align items vertically to the center */
  flex-wrap: wrap;
  flex: auto;
}

#flexRowStretch {
  display: flex;
  flex-direction: row;
  justify-content: center; /* Align items horizontally to the center */
  align-items: stretch; /* Align items vertically to the center */
  flex-wrap: wrap;
  flex: auto;
}


#flexColStart {
  display: flex;
  flex-direction: column;
  justify-content: space-between; /* aligns items along the main axis */
  align-items: flex-start; 
  flex-wrap: wrap;

}

#rowChild {
  flex: 1;
}